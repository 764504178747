import styles from "./SwipeableList.module.scss";

import React, { Component } from "react";

class SwipeableListItem extends Component {
  listElement;
  wrapper;
  background;

  dragStartX = 0;
  left = 0;
  dragged = false;

  startTime;
  fpsInterval = 1000 / 60;

  listElement = null;
  wrapper = null;
  background = null;

  componentDidMount() {
    window.addEventListener("mouseup", this.onDragEndMouse);
    window.addEventListener("touchend", this.onDragEndTouch);
  }

  componentWillUnmount() {
    window.removeEventListener("mouseup", this.onDragEndMouse);
    window.removeEventListener("touchend", this.onDragEndTouch);
  }

  onDragStartMouse = evt => {
    this.onDragStart(evt.clientX);
    window.addEventListener("mousemove", this.onMouseMove);
  };

  onDragStartTouch = evt => {
    const touch = evt.targetTouches[0];
    this.onDragStart(touch.clientX);
    window.addEventListener("touchmove", this.onTouchMove);
  };

  onDragStart = clientX => {
    this.dragged = true;
    this.dragStartX = clientX;
    this.listElement.className = styles.ListItem;
    this.startTime = Date.now();
    requestAnimationFrame(this.updatePosition);
  };

  onDragEndMouse = evt => {
    window.removeEventListener("mousemove", this.onMouseMove);
    this.onDragEnd();
  };

  onDragEndTouch = evt => {
    window.removeEventListener("touchmove", this.onTouchMove);
    this.onDragEnd();
  };

  onDragEnd = () => {
    if (this.dragged) {
      this.dragged = false;

      const threshold = this.props.threshold || 0.3;

      if (this.left < this.listElement.offsetWidth * threshold * -1) {
        this.left = -this.listElement.offsetWidth * 2;
        this.wrapper.style.maxHeight = 0;
        this.onSwiped();
      } else {
        this.left = 0;
      }

      this.listElement.className = styles.BouncingListItem;
      this.listElement.style.transform = `translateX(${this.left}px)`;
    }
  };

  onMouseMove = evt => {
    const left = evt.clientX - this.dragStartX;
    if (left < 0) {
      this.left = left;
    }
  };

  onTouchMove = evt => {
    const touch = evt.targetTouches[0];
    const left = touch.clientX - this.dragStartX;
    if (left < 0) {
      this.left = left;
    }
  };

  updatePosition = () => {
    if (this.dragged) requestAnimationFrame(this.updatePosition);

    const now = Date.now();
    const elapsed = now - this.startTime;

    if (this.dragged && elapsed > this.fpsInterval) {
      this.listElement.style.transform = `translateX(${this.left}px)`;

      const opacity = (Math.abs(this.left) / 100).toFixed(2);
      if (opacity < 1 && opacity.toString() !== this.background.style.opacity) {
        this.background.style.opacity = opacity.toString();
      }
      if (opacity >= 1) {
        this.background.style.opacity = "1";
      }

      this.startTime = Date.now();
    }
  };

  onClicked = () => {
    if (this.props.onSwipe) {
      this.props.onSwipe();
    }
  };

  onSwiped = () => {
    if (this.props.onSwipe) {
      this.props.onSwipe();
    }
  };

  render() {
    return (
      <>
        <div className={styles.Wrapper} ref={div => (this.wrapper = div)}>
          <div
            ref={div => (this.background = div)}
            className={styles.Background}
          >
            {this.props.background ? (
              this.props.background
            ) : (
              <span>Delete</span>
            )}
          </div>
          <div
            onClick={this.onClicked}
            ref={div => (this.listElement = div)}
            onMouseDown={this.onDragStartMouse}
            onTouchStart={this.onDragStartTouch}
            className={styles.ListItem}
          >
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}

export default SwipeableListItem;
