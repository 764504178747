import React, { Component } from "react";
import styles from "./ListContainer.module.scss";

class SwipeableList extends Component {
  render() {
    // const { children } = this.props;

    // const childrenWithProps = React.Children.map(children, child => {
    //   if (!child.props.background) {
    //     return React.cloneElement(child, { background: this.props.background });
    //   }
    //   return child;
    // });

    return <div className={styles.List}>{this.props.children}</div>;
  }
}

export default SwipeableList;
